import { useEffect, useState } from "react";
import { useModel } from "../model/index_model";
import './header_nav.scss';
import { home_nav, other_nav } from "../../utils/home_nav";
import { useNavigate } from "react-router-dom";
import { header_logo, obj } from "../../utils/registry";
interface nav_item extends obj {
    url?: string,
    id?: string,
}
interface props {
    fixed?: boolean
}
function HeaderNav(props: props) {
    let { page, setanchor } = useModel();
    const navigate = useNavigate();
    // 鼠标浮动索引
    let [hover, set_hover] = useState<number>()
    // 第二导航栏总动画时间
    const timeout = 1;
    // 第二导航栏单元基础动画时间
    const ani_time = 0.4;
    // 最多按照0.15s延迟处理的第二导航单元数量，+1是因为第一个的延迟为0s
    const maxlength = (timeout - ani_time) / 0.15 + 1;
    function enter_topnav_item(i: number) {/* 鼠标进入导航单元 */
        set_hover(i);
    }
    function leave_topnav_item(i: number) {/* 鼠标离开导航单元 */
        set_hover(undefined);
    }
    function jump(el: nav_item, father?: nav_item) {/* 跳转 */
        if (el.url) {
            navigate(el.url);
        } else if (father && father.url) {
            if (father.url === page) {
                setanchor(el.id);
            } else {
                setanchor(el.id, father.url);
            }
        }
    }
    return (
        <div className={[props.fixed ? 'fixed' : ''].join(' ')} id="header_nav">
            <ZhongTian_LOGO></ZhongTian_LOGO>
            <div className="_nav">
                <div className="nav_part">
                    <div className="nav_head">
                        {
                            other_nav && other_nav.map((el, i) => {
                                return <div onClick={() => (el.id === 'login' ? jump({ id: el.id }, el) : jump(el))} key={i}>{el.name}</div>
                            })
                        }
                    </div>
                    <div className="page_nav">
                        {home_nav && home_nav.map((el, i) => {
                            return <div key={i} onMouseEnter={() => enter_topnav_item(i)} onMouseLeave={() => leave_topnav_item(i)}
                                className={[page == el.url ? 'active' : '', hover == i ? 'hover' : 'hidden'].join(' ')}>
                                <div className="title" onClick={() => jump(el)}>{el.name}</div>
                                {el.children && <div className={["sceond_nav"].join(' ')} style={hover != i ? { transitionDelay: el.children.length > maxlength ? `${timeout}s` : `${(el.children.length - 1) * 0.15 + ani_time}s` } : {}}>
                                    {el.children.map((ee, ii) => {
                                        return el.children && <div key={ii} style={hover == i ? {
                                            transitionDelay: el.children?.length > maxlength ? `${ii * (timeout - ani_time) / (el.children.length - 1)}s` : `${ii * 0.15}s`
                                        } : { transitionDelay: el.children.length > maxlength ? `${timeout - ani_time - ii * (timeout - ani_time) / (el.children.length - 1)}s` : `${(el.children.length - 1) * 0.15 - ii * 0.15}s` }}
                                            onClick={() => jump(ee, el)}>
                                            <div className="title">{ee.name}</div>
                                        </div>
                                    })}
                                </div>}
                                {el.children && <div className="haschild">
                                    <svg viewBox="0 0 16 16">
                                        <path d="M2 6 L7 10 Q8 11 9 10 L14 6" strokeWidth="2" fill="none" strokeLinecap="round"></path>
                                    </svg>
                                </div>}
                            </div>
                        })}
                    </div>
                </div>
                <div className="nav_end"></div>
            </div>
        </div>
    )
}

export function ZhongTian_LOGO() {
    return (
        <div className="logo">
            <div className="img_part">{header_logo.svg}</div>
            <div className="txt_part">
                <div className="cn_title"><div>{header_logo.cn}</div></div>
                <div className="eng_title"><div>{header_logo.eng}</div></div>
            </div>
        </div>
    )
}


export default HeaderNav;