import { APILoader, Map, Marker, NavigationControl, Provider, ScaleControl, useMapContext } from '@uiw/react-baidu-map';
interface props {
    keys: string,
    zoom: number,
    center: object | string,
}
function BaiduMap(props: props) {
    let { keys, center, zoom } = props;
    return (
        <APILoader key={keys}>
            <Provider>
                <Map center={center} zoom={zoom}>
                    <ScaleControl></ScaleControl>
                    <NavigationControl></NavigationControl>
                    <Marker position={center} animation={2}></Marker>
                </Map>
            </Provider>
        </APILoader>
    )
}

export default BaiduMap;