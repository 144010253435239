import { obj } from "../../utils/registry";
import './company_item.scss';

interface props {
    data: obj
}
function CompanyItem(props: props) {
    let { data } = props;
    return (
        <div className="company_item">
            <div>
                <div className="imgbox">
                    <img src={data.logo} alt="" />
                </div>
                <div className="content">
                    <div className="allinfo">
                        <div className="title">{data.name}</div>
                        <div className="address">{data.address}</div>
                        <div className="contract">
                            <div>{data.charge}</div>
                            <div>{data.phone}</div>
                        </div>
                    </div>
                    <div className="btn_box">
                        {data.href ? <div className="offweb">进入官网</div> : undefined}
                        {data.needmodel ? <div className="detail">查看详情</div> : undefined}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyItem;