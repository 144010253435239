
import { useNavigate } from 'react-router-dom';
import { home_nav } from '../../utils/home_nav';
import { bottom_info } from "../../utils/registry";
import { ZhongTian_LOGO } from './header_nav';
import './bottom_nav.scss';
import { useState } from 'react';

function BottomNav() {
    let [readcount, set_readcount] = useState<number>(114514);
    const navigate = useNavigate();
    function jump(url: string, out: boolean = false) {
        if (!out)
            navigate(url);
        else window.location.href = url;
    }
    return (
        <div id="bottom_nav">
            <div className='hr'></div>
            <div className='nav_content'>
                <ZhongTian_LOGO></ZhongTian_LOGO>
                <div className='nav_list'>
                    {home_nav && home_nav.map((el: any, i: number) => {
                        return <div key={i}><span onClick={() => jump(el.url)}>{el.name}</span></div>
                    })}
                </div>
            </div>
            <div className='content'>
                <div className='content_left'>
                    <div>{bottom_info.copyright}</div>
                    <div className='record'>
                        <div className='cip' onClick={() => jump(bottom_info.icp.url,true)}>
                            <img src={bottom_info.badge}></img>
                            <div className='txt'>{bottom_info.icp.cn}</div>
                        </div>
                        <div className='security' onClick={() => jump(bottom_info.security.url,true)}>
                            <img src={bottom_info.badge} alt="" />
                            <div className='txt'>{bottom_info.security.cn}</div>
                        </div>
                        <div>隐私政策</div>
                    </div>
                    <div className='read_count'>主页访问量：{readcount}</div>
                </div>
                <div className='content_right'>
                    <div className='allname'>公司全称：{bottom_info.fullname}</div>
                    <div className='addres'>地址：{bottom_info.address}</div>
                </div>
            </div>
        </div>
    )
}

export default BottomNav;