import { useModel } from '../../../components/model/index_model';
import Shopping from '../../../components/shopping/shopping';
import Title from '../../../components/title/title';
import { material_part } from '../../../utils/registry';
import './shopc.scss';
function Material() {
    let { cn, eng, id, filter, goods } = material_part;
    let { vh } = useModel();
    return (
        <div className="shopc" id={id} style={{ minHeight: `${vh}px` }}>
            <Title name={cn} name_eng={eng}></Title>
            <Shopping filter={filter} list={goods}></Shopping>
        </div>
    )
}

export default Material;