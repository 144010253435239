import { useEffect } from "react";
import { useModel } from "../../components/model/index_model";
import Title from "../../components/title/title";
import { warehouse_part } from "../../utils/registry";
import './warehouse.scss';
import WarehouseItem from "../../components/warehouse/warehouse_item";
function Warehouse() {
    let { cn, eng, id, items } = warehouse_part;
    let { vh } = useModel();
    return (
        <div id={id} style={{ minHeight: `${vh}px` }}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                {
                    items.length !== 0 ? items.map((el: any, i: number) => {
                        return <WarehouseItem key={i} data={el}></WarehouseItem>
                    }) : <div className="nodata">暂无仓库</div>
                }
            </div>
        </div>
    )
}

export default Warehouse;