import { useEffect, useRef, useState } from "react";
import { obj } from "../../utils/registry";
import './warehouse_item.scss';
import InView from "../inview/inview";

interface props {
    data: obj
}
type direction = 'prev' | 'next';
function WarehouseItem(props: props) {
    let { data } = props;
    let { images, price, title } = data;
    let [active, set_active] = useState<number>(0);
    let [turn_direction, set_turn_direction] = useState<direction>('next');
    let [turnable, set_turnable] = useState<boolean>(true);
    const timer = useRef<any>(null);
    useEffect(() => {
        start(active);
        return (() => {
            clearInterval(timer.current);
        })
    }, [])
    function start(index: number) {/* 启动计时器 */
        timer.current = setInterval(() => {
            // 修改index防止react数据不刷新问题
            index = index === images.length - 1 ? 0 : index + 1;
            // index = index === 0 ? images.length - 1 : index - 1;
            turn(index);
        }, 2000)
    }
    function read_details(e: any) {
        e.stopPropagation();
    }
    function re_start_turn(index: number, direction?: direction) {/* 重新启动方法 */
        // 停止计时器后进行旋转，旋转结束后重启计时器
        let time = timer.current;
        if (time) clearInterval(timer.current);
        turn(index, direction).then((index) => {
            if (time) start(index);
        })
    }
    function turn_prev() {/* 旋转至上一个 */
        re_start_turn(active === 0 ? images.length - 1 : active - 1, 'prev');
    }
    function turn_next() {/* 旋转至下一个 */
        re_start_turn(active === images.length - 1 ? 0 : active + 1);
    }
    function turn(index: number, direction: direction = 'next') {
        return new Promise<number>((resolve, reject) => {
            if (!turnable) return;
            set_turnable(false);
            set_turn_direction(direction);
            function _turn(num: number) {
                if (num === index) {
                    // 当旋转至目标索引时，可以进行旋转，并回调，这里赋值active，避免react数据不刷新问题
                    set_turnable(true);
                    active = index;
                    resolve(index)
                } else {
                    // 根据旋转方向，逐个索引进行旋转
                    num = direction == 'next' ? num === images.length - 1 ? 0 : num + 1 : num === 0 ? images.length - 1 : num - 1;
                    set_active(num);
                    setTimeout(() => {
                        _turn(num)
                    }, (950 / Math.abs(active - index)));
                }
            }
            setTimeout(() => {
                _turn(active);
            }, 50);
        })
    }
    return (
        <InView className="warehouse_item" >
            <div className="base" onClick={read_details}></div>
            <div className="img_list">
                {
                    images.map((el: string, i: number) => {
                        return <div className={['img_box', active === i ? 'active'
                            : active === 0 && i === images.length - 1 || i === active - 1 ? 'prev'
                                : active === images.length - 1 && i === 0 || i === active + 1 ? 'next'
                                    : turn_direction === 'next' && (active === 1 && i === images.length - 1 || active === 0 && i === images.length - 2 || i === active - 2) ? 'prev_behind'
                                        : turn_direction === 'prev' && (active === images.length - 2 && i == 0 || active === images.length - 1 && i == 1 || i === active + 2) ? 'next_behind'
                                            : `${turn_direction}_behind`, `${active}`].join(' ')} key={i}>
                            <div><img src={el}></img></div>
                        </div>
                    })
                }
            </div>
            <div className="info">
                <div className="title">
                    <span>{title}</span>
                </div>
                <div className="price_part">
                    {price ? <span className="yuan">¥</span> : undefined}
                    <span className="price">{price || '待定'}</span>
                </div>
            </div>
            <div className="left_btn btn" onClick={turn_prev}>
                <svg viewBox="0 0 20 20">
                    <path d="M18 5 h-4 l-5 5 l5 5 h4 l-5 -5 l5 -5"></path>
                    <path d="M11 5 h-4 l-5 5 l5 5 h4 l-5 -5 l5 -5"></path>
                </svg>
            </div>
            <div className="right_btn btn" onClick={turn_next}>
                <svg viewBox="0 0 20 20">
                    <path d="M2 5 h4 l5 5 l-5 5 h-4 l5 -5 l-5 -5"></path>
                    <path d="M9 5 h4 l5 5 l-5 5 h-4 l5 -5 l-5 -5"></path>
                </svg>
            </div>
        </InView>
    )
}

export default WarehouseItem;