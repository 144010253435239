import React, { useEffect, useRef, useState } from "react";
import { useModel } from "../model/index_model";
interface props {
    children: JSX.Element | Array<JSX.Element | undefined>,
    className?: string | Array<string>,
    set?: React.Dispatch<React.SetStateAction<boolean>> | Function,
}

function InView(props: props) {
    let { children, className, set } = props;
    let [inview, set_inview] = useState<boolean>(false);
    let { top, vh, dur } = useModel();
    let [timer, set_timer] = useState<any>();
    let box = useRef<any>();
    useEffect(() => {
        if (!box.current) return;
        clearTimeout(timer);
        let _timer = setTimeout(() => {
            clearTimeout(_timer);
            if (!box.current) return;
            let _top = box.current.getBoundingClientRect().top;
            let _h = box.current.clientHeight;
            if (_top > -_h && _top < vh) set_inview(true);
            else set_inview(false);
        }, (dur * 1000 + 50));
        set_timer(_timer);
    }, [top, vh])
    useEffect(() => {
        if (set) set(inview);
    }, [inview])
    return (
        <div className={['inview', inview ? 'show' : ''].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')} ref={box}>
            {children}
        </div>
    )
}

export default InView;