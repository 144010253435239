import { useEffect, useState } from "react";
import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import Wave from "../../../../components/wave/wave";
import { cases_item, cases_part, obj } from "../../../../utils/registry";
import './cases.scss';
function Cases() {
    let { id, cn, eng, items, bg } = cases_part;
    let [show, set_show] = useState<boolean>(false);
    let [cases, set_cases] = useState<Array<cases_item>>([]);
    useEffect(() => {
        set_cases(items.filter((el: obj, i: number) => i < 4))
    }, [items])
    return (
        <Fullpage id={id} style={{ backgroundImage: `url(${bg})` }} className={[show ? 'show' : '']}>
            <Wave className="title_box" color="rgb(255,152,24)" incolor={['rgb(255, 170, 67)', 'rgb(255, 199, 130)', 'rgb(255, 226, 190)']}>
                <Title name={cn} name_eng={eng}></Title>
            </Wave>
            <InView className={id} set={set_show}>
                {
                    cases.length >= 0 ? cases.map((el: any, i: number) => {
                        return <div className="cases_item" key={i}>
                            <img src={el.img}></img>
                            <div className="title">
                                {el.cn.slice(0, 2).split('').map((txt: string, ind: number) => {
                                    return <div className="txt" key={ind}>{txt}</div>
                                })}
                            </div>
                            <div className="content">
                                <div>
                                    <div className="content_title">{el.cn}</div>
                                    <div className="content_txt">
                                        {el.txt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <div></div>
                }
            </InView>
        </Fullpage>
    )
}

export default Cases;