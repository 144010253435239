import CompanyItem from "../../components/company/company_item";
import { useModel } from "../../components/model/index_model";
import Title from "../../components/title/title";
import { company_part, obj } from "../../utils/registry";
import './company.scss';
function Company() {
    let { cn, eng, id, companys } = company_part
    let { vh } = useModel();
    return (
        <div id={id} style={{ minHeight: `${vh}px` }}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                {
                    companys.length !== 0 ?
                        <div className="company_box">
                            {
                                companys.map((el: obj, i: number) => {
                                    return <CompanyItem key={i} data={el}></CompanyItem>
                                })
                            }
                        </div> :
                        <div className="nodata">
                            <div>暂无合作</div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Company;