import { useEffect, useRef, useState } from 'react';
import './cardbox3d.scss';
export interface autoplay{
    sx:number,
    sy:number,
    ex:number,
    ey:number,
    dur:number,
    z?:boolean,
}
interface props{
    className?:string | Array<string>,
    children:JSX.Element | Array<JSX.Element>,
    sonclassName?:string | Array<string>,
    multiple?:number,
    autoplay?:autoplay,
}
function CardBox3D(props:props){
    let {className,children,sonclassName,multiple = 20,autoplay} = props;
    let cardbox3d = useRef<any>();
    let [x,set_x] = useState<number>(0);
    let [y,set_y] = useState<number>(0);
    let [play,set_play] = useState<boolean>(false);
    let [playing,set_playing] = useState<boolean>(false);
    let [playtimer,set_timer] = useState<any>();
    let [playz,set_playz] = useState<boolean>(false);
    useEffect(()=>{
        if(autoplay){
            set_play(true);
        }else{
            set_play(false);
        }
    },[autoplay])
    useEffect(()=>{
        if(play && autoplay){
            let _sx = Math.abs(autoplay.sx) > 1 ? autoplay.sx : cardbox3d.current.offsetWidth * (autoplay.sx - 0.5) / multiple;
            let _sy = Math.abs(autoplay.sy) > 1 ? -autoplay.sy : cardbox3d.current.offsetHeight * (autoplay.sy - 0.5) / multiple;
            set_x(_sx)
            set_y(_sy)
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_playing(true);
                timer = setTimeout(() => {
                    clearTimeout(timer);
                    let _ex = Math.abs(autoplay!.ex) > 1 ? autoplay!.ex : cardbox3d.current.offsetWidth * (autoplay!.ex - 0.5) / multiple;
                    let _ey = Math.abs(autoplay!.ey) > 1 ? -autoplay!.ey : cardbox3d.current.offsetHeight * (autoplay!.ey - 0.5) / multiple;
                    set_x(_ex)
                    set_y(_ey)
                    if(autoplay!.z){
                        timer = setTimeout(() => {
                            clearTimeout(timer);
                            set_playz(true);
                            if(_sx !== _ex)
                                set_x(_ex - _sx > 0 ? _sx + 360 : _sx - 360);
                            if(_sy !== _ey)
                                set_y(_ey - _sy > 0 ? _sy - 360 : _sy + 360);
                            timer = setTimeout(() => {
                                set_play(false);
                                timer = setTimeout(() => {
                                    set_play(true);
                                }, 50);
                            }, 500);
                            set_timer(timer);
                        }, autoplay!.dur * 1000);
                        set_timer(timer);
                    }
                }, 50);
                set_timer(timer);
            }, 50);
            set_timer(timer);
        }else{
            set_playz(false);
            set_playing(false);
            clearTimeout(playtimer);
            set_timer(null);
        }
    },[play])
    function mouse_move(e:any){
        if(!cardbox3d.current) return;
        set_play(false);
        set_x(((e.clientX - cardbox3d.current.offsetLeft - cardbox3d.current.offsetWidth / 2) / multiple));
        set_y((-(e.clientY - cardbox3d.current.getBoundingClientRect().top - cardbox3d.current.offsetHeight / 2) / multiple));
    }
    function mouse_leave(){
        set_x(0);
        set_y(0);
        set_play(true);
    }
    return( 
        <div className={['cardbox3d'].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')}
            onMouseMove={mouse_move} ref={cardbox3d} onMouseLeave={mouse_leave} onMouseEnter={mouse_move}>
            <div style={{transform:`rotateY(${x}deg) rotateX(${y}deg)`,transition:`${autoplay && playing ? `all ${playz ? .5 : autoplay.dur}s` :'none'}`}} className={sonclassName ? typeof sonclassName === 'string' ? sonclassName  : sonclassName.join(' ') : undefined}>{children}</div>
        </div>
    )
}

export default CardBox3D;