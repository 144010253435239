import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import { member_part } from "../../../../utils/registry";
import './member.scss';

function Member() {
    let { id, cn, eng, img } = member_part;
    return (
        <Fullpage id={id}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                <InView>
                    <img src={img} alt="" />
                </InView>
            </div>
        </Fullpage>
    )
}

export default Member;