import React from "react"
let toelement = (e: any) => React.createElement(e.default);
export interface coordinate {
    x: number,
    y: number,
    cn: string
}
export interface obj {
    [key: string]: any
}
export interface _txt extends obj {
    cn: string,
    eng: string,
    img?: string,
    img_active?: string,
    svg?: JSX.Element,
}
/* 网站基础信息 */
export const web_basic = {
    name: '众田科技',
    fullname: '众田（广州）科技有限公司',
    eng: 'ZhongTian Technology',
    fullname_eng: 'ZhongTian (GZ) Technology Co., Ltd',
    address: '广东省番禺天安科技园14号楼410',
}
/* 备案 */
export const record = {
    icp: {
        cn: '粤ICP备2022100289号',
        url: 'https://beian.miit.gov.cn/'
    },
    security: {
        cn: '粤公网安备44011302004528号',
        url: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302004528'
    },
    badge: require('../assets/image/icon/beian.png')
}
/* 底部导航 */
export const bottom_info = {
    ...web_basic,
    ...record,
    copyright: `© 2022-2024 ${web_basic.fullname} 版权所有 ${web_basic.name} All Rights Reserved.`,
}
/* 顶部导航中各导航文本 */
export const header_nav: { [key: string]: _txt } = {
    home: {
        cn: '首页',
        eng: 'Home',
    },
    plat: {
        cn: '平台介绍',
        eng: 'Platform Introduction'
    },
    business: {
        cn: '业务模块',
        eng: "Business"
    },
    development: {
        cn: '公司发展',
        eng: 'Company Development'
    },
    cases: {
        cn: '经营案例',
        eng: 'Business Case'
    },
    more_cases: {
        cn: '更多案例',
        eng: 'More Cases',
    },
    equipment: {
        cn: '设备',
        eng: 'Equipment'
    },
    material: {
        cn: '材料',
        eng: 'Material'
    },
    warehouse: {
        cn: '仓库',
        eng: 'Warehouse'
    },
    company: {
        cn: '企业',
        eng: 'Company'
    },
    about_us: {
        cn: '关于我们',
        eng: "About Us"
    },
    company_introduce: {
        cn: '公司介绍',
        eng: 'Company Introduce'
    },
    corporate_culture: {
        cn: '企业文化',
        eng: 'Corporate Culture'
    },
    management_idea: {
        cn: '经营理念',
        eng: 'Management Idea'
    },
    member_structure: {
        cn: '成员框架',
        eng: 'Member Structure'
    },
    honors: {
        cn: '公司荣誉',
        eng: 'Company Honors'
    },
    contact_us: {
        cn: '联系我们',
        eng: 'Contact Us'
    },
    login: {
        cn: '登录',
        eng: 'Login'
    },
    recruit: {
        cn: '人才招聘',
        eng: 'Recruit'
    }
}
/* 顶部logo名称 */
export const header_logo: _txt = {
    cn: web_basic.name,
    eng: web_basic.eng,
    svg: toelement(require('../components/svg/logo'))
}
/* 轮播图 */
export const swiper = [
    require('../assets/image/swiper/1.jpg'),
    require('../assets/image/swiper/2.jpg'),
    require('../assets/image/swiper/3.jpg'),
    require('../assets/image/swiper/4.jpg'),
    require('../assets/image/swiper/5.jpg'),
]
/* 首页-业务模块 */
export const business_part: _txt = {
    ...header_nav.business,
    id: 'business',
    items: [
        {
            cn: '数字科技',
            eng: `digital technology`,
            text: {
                cn: `${web_basic.name}拥有先进系统开发团队，现已开发有贸易、无车（无船）承运人、物业管理、物流等多个数字平台，能为客户提供快速、优质、多元化、综合统计、大数据服务等现成的系统平台，也可以贴合客户要求，为客户量身订做专门的数字系统平台，随时为客户的业务保驾护航。`,
                eng: `${web_basic.eng} has an advanced system development team and has developed multiple digital platforms including trade, non vehicle (non vessel) carriers, property management, logistics, etc. We can provide customers with fast, high-quality, diversified, comprehensive statistics, big data services, and other ready-made system platforms. We can also tailor a specialized digital system platform to meet customer requirements and provide timely support for their business.`
            },
            svg: toelement(require('../components/svg/digital_technology'))
        },
        {
            cn: '产业园',
            eng: `industrial parks`,
            text: {
                cn: `${web_basic.name}在全国各地拥有各类型的高新产业园区、科技产业园、数字产业园、物流产业园等管理园区，为进入园区的客户提代各种业务扶持计划和优惠的财税统筹方案，也能为进入园区的各中小型企业提供资金帮扶，为各客户提供一个高速、高效、高质、高优惠的产业孵化基地。`,
                eng: `${web_basic.eng} has various types of high-tech industrial parks, science and technology industrial parks, digital industrial parks, logistics industrial parks and other management parks across the country. We provides various business support plans and preferential financial and tax coordination schemes for customers entering the parks, as well as financial assistance for small and medium-sized enterprises entering the parks, providing customers with a high-speed, efficient, high-quality, and highly favorable industrial incubation base.`
            },
            img: require('../assets/image/icon/industrial_park.png'),
            img_active: require('../assets/image/icon/industrial_park_active.png'),
        },
        {
            cn: '贸易',
            eng: `trade`,
            text: {
                cn: `${web_basic.name}提供各类大宗商品的交易平台，有多年的大宗商品贸易经验，在钢材、煤炭方面顾为突出，拥有一定的深入了解和行业资源。${web_basic.name}拥有完善的贸易操作团队，能为客户提供完善的贸易交易平台服务，并提供专业的贸易相关问题的咨询和解答，提供完善的解决方案。`,
                eng: `${web_basic.eng} provides a trading platform for various commodities and have many years of experience in commodity trading. We have outstanding expertise in steel and coal, and have a certain level of in-depth understanding and industry resources. We have a well-established trade operation team that can provide customers with comprehensive trade transaction platform services, and offer professional consultation and answers to trade-related issues, providing comprehensive solutions.`
            },
            svg: toelement(require('../components/svg/trade'))
        },
        // {
        //     cn:'人力',
        //     eng:'human_resources',
        //     text:`${web_basic.name}拥专业的人力资源服务队伍，有丰富的人力资源和灵活用工操作管理经验，能为客户更好提高劳动效率，充分发挥核心竞争力，增强企业应变能力。公司拥有自主研发的管理系统，能帮助管理者通过有效组织管理降低成本和加速增长来创造价值链利润，帮助公司实现全面的员工规范管理，实现完善的薪酬计算和缴纳机制，建立合法合规的合同体制，实现企业人力管理的无忧服务。`
        // }
    ]
}
/* 首页-平台介绍模块 */
export const plat_part: _txt = {
    ...header_nav.plat,
    id: 'plat',
    bg: require('../assets/image/bg/ring.jpg'),
    img: require('../assets/image/bg/cloudworld.jpg'),
    title: {
        cn: web_basic.name,
        eng: web_basic.eng,
    },
    text: {
        cn: `${web_basic.name}致力于将科技与物业相结合，以物业为核心，围绕着${business_part.items.map((el: any) => el.cn).join('、')}等三个主要业务模块，坐立于广州开展相关业务。`,
        eng: `${web_basic.eng} is committed to combining technology with property management, with property management as the core, focusing on three main modules: ${business_part.items.map((el: any) => el.eng).join(', ')}, and operating related businesses in Guangzhou.`,
    }
}
/* 首页-公司发展，地图省会坐标 */
const coordinate = [
    { x: 1400, y: 1373, cn: '广州', eng: 'Guangzhou' },
    { x: 1200, y: 1393, cn: '南宁', q: { x: 1295, y: 1330, l: 222 }, eng: 'Nanning' },
    { x: 1630, y: 1213, cn: '福州', q: { x: 1490, y: 1257, l: 290 }, eng: 'Fuzhou' },
    { x: 1488, y: 1120, cn: '南昌', q: { x: 1420, y: 1238, l: 270 }, eng: 'Nanchang' },
    { x: 1484, y: 770, cn: '济南', q: { x: 1400, y: 1066, l: 610 }, eng: 'Jinan' },
    { x: 1380, y: 864, cn: '郑州', q: { x: 1410, y: 1118, l: 510 }, eng: 'Zhengzhou' },
    { x: 1328, y: 733, cn: '太原', q: { x: 1400, y: 1049, l: 644 }, eng: 'Taiyuan' },
    { x: 1025, y: 826, cn: '兰州', q: { x: 1300, y: 1040, l: 680 }, eng: 'Lanzhou' },
    { x: 1288, y: 613, cn: '呼和浩特', q: { x: 1380, y: 988, l: 770 }, eng: 'Hohhot' },
]
/* 首页-公司发展 */
export const development_part: _txt = {
    ...header_nav.development,
    id: 'development',
    text: {
        cn: `${web_basic.fullname}总部坐落于广州，业务范围覆盖我国${coordinate.map((el: any) => el.cn).join('、')}等多个省份的省会城市，有着广泛的用合作公司与用户。`,
        eng: `${web_basic.fullname_eng}'s headquarters is located in Guangzhou, with business scope covering provincial capitals such as ${coordinate.map((el: any) => el.eng).join('、')} in China, and has a wide range of cooperative companies and users.`
    },
    map: require('../assets/image/bg/bigmap.jpg'),
    coordinate,
}
export interface cases_item extends obj {
    cn: string,
    img: string,
    txt: string,
    type: number,
}
/* 首页-经营案例 */
export const cases_part: _txt = {
    ...header_nav.cases,
    id: 'cases',
    bg: require('../assets/image/bg/ball_center.jpg'),
    items: [
        {
            cn: '园区',
            img: require('../assets/image/bg/park.jpg'),
            txt: '在传统的园区管理模式下，使用科技与自动化与园区管理相结合，打造一个科技化现代化的园区，给园区各类型用户提供更智能、更方便的操作',
            type: 0
        },
        {
            cn: '贸易',
            img: require('../assets/image/bg/trade.jpg'),
            txt: '贸易出口',
            type: 2
        },
        {
            cn: '仓储',
            img: require('../assets/image/bg/storage.jpg'),
            txt: '通过仓储促进产业发展',
            type: 1
        },
        {
            cn: '物流',
            img: require('../assets/image/bg/transport.jpg'),
            txt: '安全稳定快捷的物流供应',
            type: 2
        },
    ]
}
/* 首页-更多案例 */
export const more_cases_part: _txt = {
    ...cases_part,
    id: 'more_cases',
    cn: '更多案例',
    eng: 'more cases',
    filter: [
        {
            cn: '科技',
            eng: 'science',
        },
        {
            cn: '产业',
            eng: 'industry',
        },
        {
            cn: '贸易',
            eng: 'trade'
        }
    ]
}
/* 关于我们-公司介绍 */
export const introduce_part: _txt = {
    ...header_nav.company_introduce,
    id: 'company_introduce',
    text: `${web_basic.fullname}（以下简称“公司”）创建于 2022 年 5 月，公司现主要经营范围：计算机软硬件及辅助设备零售;人工智能双创服务平台;互联网数据服务;再生资源加工;物联网技术服务;网络与信息安全软件开发;软件外包服务;软件开发;区块链技术相关软件和服务;宠物食品及用品批发;五金产品批发;家用电器销售;日用百货销售;食用农产品批发;谷物销售;再生资源回收;二手车交易市场经营;安全系统监控服务;商业综合体管理服务;人工智能通用应用系统;公共事业管理服务;企业管理;从事与外国（地区）企业相关的非营利性业务活动;电动汽车充电基础设施运营;企业管理咨询;安全技术防范系统设计施工服务;物业服务评估;装卸搬运;劳务服务;以自有资金从事投资活动;人工智能行业应用系统集成服务;项目策划与公关服务;商务代理代办服务;办公服务;包装服务;市场营销策划;信息咨询服务;供应链管理服务;港口货物装卸搬运活动;园区管理服务;仓储设备租赁服务;非居住房地产租赁;物业管理;普通货物仓储服务;计算机及通讯设备租赁;汽车租赁;计算机系统服务;信息技术咨询服务;对外承包工程;食用农产品零售;食品经营;食品销售;互联网直播技术服务;互联网信息服务。
    公司设于经济发达的一线城市广州。公司组织架构清晰，设有市场、运营、行政管理及财务管理等业务部门，公司不仅办公环境优雅，还有一支精干、博学、协作的团队，可以满足客户不同的服务需求。
    公司秉承诚信经营、全方位服务，为客户提供专业、诚信、安全、微笑、超值的 5S 服务的理念。拓展公司业务，使物流、信息流，资金流三流合一，为客户提供更便利的服务，达到双赢！`,
    img: require('../assets/image/bg/company.jpg'),
}
/* 关于我们-企业文化 */
export const culture_part: _txt = {
    ...header_nav.corporate_culture,
    id: 'corporate_culture',
    bg: require('../assets/image/bg/culture.jpg'),
    text: [
        { title: '公司宗旨', content: '为员工谋福利，为企业创价值，为社会添和谐' },
        { title: '经营理念', content: '以服务铸品牌，靠诚信拓市场' },
        { title: '服务理念', content: '专业提升效率，质量提升价值' },
        { title: '人才理念', content: '德才兼备，勇于担当，敬业为本' },
    ]
}
/* 关于我们-经营理念 */
export const idea_part: _txt = {
    ...header_nav.management_idea,
    id: 'management_idea',
    text: {
        cn: '我们的理念:“诚信为本、实力为先，全心全意为客户”，我们公司秉承客户至上、服务至上的经营理念，以卓越的服务品质、高端的财税管理、专业的技术实力、技术精湛的客户服务团队，保障客户在飞速发展的高速路上驰骋，又以稳固、发展、忠诚、高效、团结与创新的精神，尊重人才注重技术，使客户享受最大的收益。',
        eng: 'Our philosophy is "integrity-based, strength first, and wholeheartedly for customers". Our company adheres to the business philosophy of customer first and service first, and guarantees customers to gallop on the rapidly developing highway with excellent service quality, high-end financial and tax management, professional technical strength, and a skilled customer service team. With the spirit of stability, development, loyalty, efficiency, unity, and innovation, we respect talents, pay attention to technology, and enable customers to enjoy maximum benefits.'
    },
    bg: require('../assets/image/bg/idea.jpg'),
}
/* 关于我们-成员架构 */
export const member_part: _txt = {
    ...header_nav.member_structure,
    id: 'member_structure',
    img: require('../assets/image/bg/members.jpg')
}
/* 关于我们-公司荣誉 */
export const honors_part: _txt = {
    ...header_nav.honors,
    id: 'honors',
    image: {
        bot: require('../assets/image/bg/bot_wave.png'),
        block: require('../assets/image/bg/orange_block.png'),
        trophy: require('../assets/image/bg/trophy.png'),
        list: [
            require('../assets/image/bg/h1.png'),
            require('../assets/image/bg/h2.png'),
            require('../assets/image/bg/h3.png'),
            require('../assets/image/bg/h4.png'),
            require('../assets/image/bg/h5.png'),
            require('../assets/image/bg/h6.png'),
        ]
    }
}
/* 关于我们-联系我们 */
export const contactus_part: _txt = {
    ...header_nav.contact_us,
    id: 'contact_us',
    name: web_basic.fullname,
    address: web_basic.address,
    phone: '13147114514',
    email: '13147114514@qq.com',
    keys: 'XM7ePbuQBZPTQF0F5iMh0Yim0qBfs51V',
    center: {
        lng: 113.3747,
        lat: 22.9873
    }
}

export interface workrule {
    title: string,
    item: Array<string>
}
export interface jobitem {
    name: string,
    img: string,
    list: Array<workrule>
}
/* 关于我们-加入我们 */
export const recruit_part: _txt = {
    ...header_nav.recruit,
    id: 'recruit',
    job: [
        {
            name: '物流运营',
            img: require('../assets/image/bg/post1.jpg'),
            list: [
                {
                    title: '薪资福利',
                    item: ['薪资面议', '五险一金', '正常双休', '享受国家正常假期']
                },
                {
                    title: '岗位职责',
                    item: [
                        '负责部门日常物流管理工作，包括：运输、仓储、配送、车辆管理等',
                        '制定和执行物流工作计划，对物流工作规范进行总结和完善',
                        '监督实施物流体系职责与管理标准',
                        '控制送货和仓储成本',
                        '参与制定与控制部门物流运作预算',
                        '制定物流解决方案，提升客户满意度',
                        '定期汇总上报各项物流管理报表'
                    ]
                },
                {
                    title: '应聘要求',
                    item: [
                        '大专及以上学历，管理类、物流类相关专业',
                        '3年以上物流相关领域管理工作经验',
                        '熟悉物流管理业务流程，有丰富的流程管理操作技能',
                        '熟悉ERP及物流信息管理系统并有实施经验',
                        '品德正直高尚，形象气质佳'
                    ]
                },
            ]
        },
        {
            name: 'C#开发工程师',
            img: require('../assets/image/bg/post2.jpg'),
            list: [
                {
                    title: '薪资福利',
                    item: ['薪资面议', '五险一金', '正常双休', '享受国家正常假期']
                },
                {
                    title: '岗位职责',
                    item: [
                        '负责平台系统的分析和设计、负责底层高性能通信模块实现和优化',
                        '参与项目产品的需求分析',
                        '负责核心模块的代码编写',
                        '协助团队技术能力提升与团队技术氛围建设，带动团队共同成长',
                        '负责软件系统的功能设计、开发和测试',
                        '软件的迭代更新并保证质量'
                    ]
                },
                {
                    title: '应聘要求',
                    item: [
                        '本科或以上学历，计算机科学与应用或相关专业',
                        '熟悉运用microsoft .net开发工具',
                        '熟练运用.net全系软件开发技术，完成代码开发工作，具备良好的编码规范',
                        '熟悉TCP/lP通信协议，熟悉异步通信编程，通信加解密库',
                        '独立工作能力，能创造性解决问题',
                        '对算法和性能优化有浓厚兴趣'
                    ]
                },
            ]
        },
    ]
}
/* 设备与材料 */
interface filter_item {
    title: string,
    prop: string,
}
export interface filter extends filter_item {
    list: Array<filter_item>
}
export interface shop {
    filter: Array<filter>,
    goods: Array<obj>,
}
/* 设备 */
export const equipment_part: _txt & shop = {
    ...header_nav.equipment,
    id: 'equipment',
    cn: '物流设备',
    filter: [
        {
            title: '设备类型', prop: 'type', list: [
                { title: '货架', prop: 'shelves' },
                { title: '托盘', prop: 'tray' },
                { title: '货车', prop: 'goodscar' },
            ]
        },
        {
            title: '品牌', prop: 'brand', list: [
                { title: '比亚迪', prop: 'BYD' },
                { title: '普拉托', prop: 'PRATO' },
            ]
        }
    ],
    goods: [
        {
            title: '货车',
            image: [require('../assets/image/faker/car1.jpg')],
            price: '',
            type: 'goodscar',
            brand: 'BYD',
            provider: '众田',
            location: '番禺天安科技园14号410',
            factory_life: 2018,
            mileage: 6.4,
            rated_load: 1.5,
            emission_standard: '国五',
            contacts_image: '',
            contacts: '',
            weixin_image: '',
            phone: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves1.jpg')],
            price: '100',
            type: 'shelves',
            provider: '',
            load: 0,
            lease: 3,
            buy: 0
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves2.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves3.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves1.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves2.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves3.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves1.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves2.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves3.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves1.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves2.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        },
        {
            title: '货架(蓝)',
            image: [require('../assets/image/faker/shelves3.jpg')],
            price: '100',
            type: 'shelves',
            provider: ''
        }
    ],
}
/* 材料 */
export const material_part: _txt & shop = {
    ...header_nav.material,
    id: 'material',
    cn: '物流材料',
    filter: [
        {
            title: '材料类型', prop: 'type', list: [
                { title: '轮胎', prop: 'tire' },
                { title: '润滑油', prop: 'lubricant' },
            ]
        },
        {
            title: '品牌', prop: 'brand', list: [
                { title: '多伦多', prop: 'Toronto' },
                { title: '米其林', prop: 'Michelin' },
            ]
        }
    ],
    goods: [
        {
            title: '轮胎',
            image: [require('../assets/image/faker/wheel1.jpg')],
            price: '',
            type: 'tire',
            brand: 'Toronto',
            provider: '众田',
            location: '番禺天安科技园14号410',
            readcount: 0,
            id: 1,
            comment_count: 0,
            contacts_image: '',
            contacts: '',
            parameter: {
                '颜色': ['黑色'],
                '规格': ['255/50R20', '255/45R19', '245/45R20', '245/45R19', '255/50R19'],
                '类型': ['普通胎']
            }
        },
        {
            title: '米其林轮胎',
            image: [require('../assets/image/faker/wheel2.jpg')],
            price: '',
            type: 'tire',
            brand: 'Michelin',
            provider: '米其林',
        },
        {
            title: '润滑油',
            image: [require('../assets/image/faker/oil1.jpg')],
            price: '',
            type: 'lubricant'
        }
    ],
}
/* 仓库 */
export const warehouse_part: _txt = {
    ...header_nav.warehouse,
    cn: '仓库总览',
    id: 'warehouse',
    items: [
        {
            "id": 1,
            "title": "赣州仓库",
            "price": 0,
            "warehouse_area": 6000,
            "warehouse_type": 1,
            "minimum_rent_area": 1500,
            "district": "江西省赣州市",
            "address": "",
            "read_count": 1494,
            "collectors_count": 0,
            "reported_count": 0,
            "images": [
                require('../assets/image/faker/ck1.jpg'),
                require('../assets/image/faker/ck2.jpg'),
                require('../assets/image/faker/ck3.jpg'),
                require('../assets/image/faker/ck4.jpg'),
                require('../assets/image/faker/ck5.jpg'),
                require('../assets/image/faker/ck6.jpg'),
                require('../assets/image/faker/ck7.jpg'),
                require('../assets/image/faker/ck8.jpg'),
                require('../assets/image/faker/ck9.jpg'),
                require('../assets/image/faker/ck10.jpg'),
                require('../assets/image/faker/ck11.jpg'),
                require('../assets/image/faker/ck12.jpg'),
            ],
            "contacts": "孙总",
            "contacts_image": "",
            "contacts_wechat_image": "",
            "contacts_phone": "13111111111",
            "company": "众田(广州)科技有限公司",
            "business_license": "",
            "business_license_image": "",
            "rental_mode": "分租",
            "starting_rent_period": 3,
            "pledge": "押1付1",
            "floor_count": "多层",
            "ground_floor_height": 0,
            "new_degree": "九成新",
            "structure": "",
            "warehouse_describe": "",
            "supporting_facilities": "",
            "publisher_id": 4,
        }
    ]
}
/* 公司 */
export const company_part: _txt = {
    ...header_nav.company,
    id: 'company',
    cn: '合作企业',
    companys: [
        {
            "id": 1,
            "name": "云工网络(广州)有限公司",
            "agent_identity_card": null,
            "address": "广州市黄埔区大沙地东319号1602房",
            "business": "物流",
            logo: require('../assets/image/faker/yg.png'),
            "charge": "孙章桥",
            "phone": 18818911501,
            "email": "1@qq.com",
            "href": "http://int-work.cn/",
            "business_license_no": null,
            "business_license_image": null,
            "cooperation": 1,
            "needmodel": 1,
            model_head_image: require('../assets/image/faker/yg_img.jpg'),
            "model_info": [
                {
                    "content": "云工网络（广州）有限公司（以下简称公司）创建于 2020 年 5 月，公司现主要经营范围：职业中介活动；代理记账；各类工程建设活动；道路货物运输， 互联网数据服务；互联网销售；网络与信息安全软件开发；信息技术咨询服务； 技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人力资源服 务；市场营销策划；软件开发；广告设计、代理；广告制作；广告发布；财务咨询；商务代理代办服务；税务服务；运输货物打包服务；仓储设备租赁服务；教 育咨询服务；工商登记代理代办。\n公司设于经济发达的一线城市广州。公司组织架构清晰，设有市场、运营、 行政管理及财务管理等业务部门，公司不仅办公环境优雅，还有一支精干、博学、 协作的团队，可以满足客户不同的服务需求。\n公司秉承诚信经营、全方位服务，为客户提供专业、诚信、安全、微笑、超值的5S服务的理念。拓展公司业务，使物流、信息流，资金流三流合一，为客户提供更便利的服务，达到双赢！",
                    "title": "公司介绍",
                    "id": "introduce"
                },
                {
                    "content": "公司宗旨：为员工谋事业、为企业创价值、为社会添和谐\n经营理念：以服务铸品牌、靠诚信拓市场\n服务理念：专业提升效率、质量提升价值\n人才理念：德才兼备、勇于担当、敬业为本",
                    "id": "culture",
                    "title": "企业文化"
                },
                {
                    "id": "idea",
                    "title": "经营理念",
                    "content": "我们的理念:“诚信为本、实力为先，全心全意为客户”，我们公司秉承客户至上、服务至上的经营理念，以卓越的服务品质、高端的财税管理、专业的技术实力、技术精湛的客户服务团队，保障客户在飞速发展的高速路上驰骋，又以稳固、发展、忠诚、高效、团结与创新的精神，尊重人才注重技术，使客户享受最大的收益。"
                }
            ]
        },
    ]
}
/* 登录 */
export const login_part: _txt = {
    ...header_nav.login,
    id: 'login',
    bg: require('../assets/image/bg/login_back.jpg'),
    title: {
        cn: '密码登录',
        eng: 'Password Login',
    },
    type: {
        tip: {
            cn: '请选择登录系统',
            eng: 'select system'
        },
        list: [
            {
                cn: '众田物业',
                eng: 'Zhongtian Property',
            },
            {
                cn: '物业系统',
                eng: 'Property System',
            },
            {
                cn: '大宗系统',
                eng: 'Dazong System'
            }
        ],
    },
    operation: [
        {
            cn: '忘记账号',
            eng: 'forget account',
            url: '/forget?type = account'
        },
        {
            cn: '忘记密码',
            eng: 'forget password',
            url: '/forget?type = password'
        },
        {
            cn: '注册',
            eng: 'register',
            url: '/register'
        }
    ],
    input: {
        account: {
            placeholder: '请输入用户名、账号或邮箱',
            rule: [
                { required: true, message: '请输入用户名、账号或邮箱' }
            ]
        },
        password: {
            placeholder: '请输入密码',
            rule: [
                { required: true, message: '密码不能为空' }
            ]
        }
    }
}