import Equipment from "./pages/equipment";
import Material from "./pages/material";

function Shopc(props: any) {
    let { type } = props;
    return (
        <div>
            {type === 'equipment' ? <Equipment></Equipment>
                : type === 'material' ? <Material></Material> : ''}
        </div>
    )
}
export default Shopc;