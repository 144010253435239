import { useEffect, useRef, useState } from "react";
import Fullpage from "../../components/fullpage/fullpage";
import { login_part } from "../../utils/registry";
import './login.scss';
import { useModel } from "../../components/model/index_model";
import { useNavigate } from "react-router-dom";
interface submit {
    account: string,
    password: string,
    type: number | undefined,
}
function Login() {
    let { id, bg, title, type, operation, input } = login_part;
    let { setanchor } = useModel();
    const navigate = useNavigate();
    let [submit, set_submit] = useState<submit>({
        account: '',
        password: '',
        type: undefined
    })
    let [mask, set_mask] = useState<boolean>(false);
    let [readyclose, set_readyclose] = useState<boolean>(false);
    let select = useRef<any>();
    useEffect(() => {
        setanchor(id)
    }, [])
    useEffect(() => {
        if (!select.current) return;
        select.current.style.setProperty('--c', type.list.length);
    }, [type.list])
    function change_value(e: any, prop: string) {
        set_submit({ ...submit, [prop]: e.target.value });
    }
    function open_select() {
        set_mask(true);
    }
    function close_select(i?: number) {
        if (readyclose) return;
        set_readyclose(true);
        if (!i && i !== 0) i = submit.type;
        set_submit({ ...submit, type: i });
        setTimeout(() => {
            set_mask(false);
            set_readyclose(false);
        }, 300);
    }
    function jump(url: string) {
        navigate(url);
    }
    function login() {
        if (!submit.type) {
            
        }
    }
    return (
        <div className="fullpage-wheel">
            <Fullpage id={id} style={{ backgroundImage: `url(${bg})` }}>
                <div className="login_box">
                    <div className="login_title">{title.cn}</div>
                    <div className="input_box">
                        <div className="md_input">
                            <div className="md_input-front">
                                <svg viewBox="0 0 16 16" width={16} height={16} stroke="rgb(73, 80, 87)">
                                    <circle fill="none" cx={8} cy={4} r={4}></circle>
                                    <path d="M1 16 a6.5 8 0 0 1 6.5 -8 M15 16 a6.5 8 0 0 0 -3 -7" fill="none"></path>
                                </svg>
                            </div>
                            <div className="md_input-conttent">
                                <input type="text" onChange={(e) => change_value(e, 'account')} value={submit.account} placeholder={input.account.placeholder} />
                            </div>
                            <div className="md_input-end"></div>
                        </div>
                        <div className="md_input">
                            <div className="md_input-front">
                                <svg viewBox="0 0 16 16" width={16} height={16} stroke="rgb(73, 80, 87)">
                                    <rect x="1" y="7.5" width="14" height="8" rx="2" ry="1" fill="none"></rect>
                                    <path d="M4 7.5 v-3 a4 4 0 1 1 8 0 v1" fill="none"></path>
                                    <path d="M8.5 10 v2" fill="none" strokeLinecap="round"></path>
                                </svg>
                            </div>
                            <div className="md_input-conttent">
                                <input type="text" onChange={(e) => change_value(e, 'password')} value={submit.password} placeholder={input.password.placeholder} />
                            </div>
                            <div className="md_input-end"></div>
                        </div>
                        <div className="md_select_down">
                            <div className="md_select_down-showpart" onClick={open_select}>
                                <div className="md_select_down-showpart-left">
                                    {submit.type || submit.type === 0 ? type.list[submit.type].cn : type.tip.cn}
                                </div>
                                <div className="md_select_down-showpart-right">
                                    <svg viewBox="0 0 16 16" width={16} height={16}>
                                        <path d="M8 5 h5 q3 0 1 2.3 l-4 4.7 q-2 2 -4 0 l-4 -4.7 q-2 -2.3 1 -2.3 h5" stroke="#aaa" fill="#aaa"></path>
                                    </svg>
                                </div>
                            </div>
                            {mask && <div className={['md_select_down-mask', readyclose ? 'close' : ''].join(' ')} onClick={() => close_select()}></div>}
                            <div className={['md_select_down-select_part', mask && !readyclose ? 'show' : ''].join(' ')} ref={select}>
                                <div className="md_select_down-select_list">
                                    {
                                        type.list.map((el: any, i: number) => {
                                            return <div className="md_select_down-select_item" key={i} onClick={() => close_select(i)}>{el.cn}</div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="login_btn">登录</div>
                        <div className="other_operation">
                            {
                                operation && operation.map((el: any, i: number) => {
                                    return <div onClick={() => jump(el.url)} key={i}>{el.cn}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Fullpage>
        </div>
    )
}

export default Login;