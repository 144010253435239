
import ContactUs from './pages/contact_us/contact_us';
import Culture from './pages/culture/culture';
import Honors from './pages/honors/honors';
import Idea from './pages/idea/idea';
import Introduce from './pages/introduce/introduce';
import Member from './pages/member/member';
function AboutUs() {
    return (
        <div className="about_us fullpage-wheel">
            <Introduce></Introduce>
            <Culture></Culture>
            <Idea></Idea>
            <Member></Member>
            <Honors></Honors>
            <ContactUs></ContactUs>
        </div>
    )
}

export default AboutUs;