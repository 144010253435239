import { useState } from "react";
import CardBox3D from "../../../../components/cardbox3d/cardbox3d";
import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import { honors_part } from "../../../../utils/registry";

import './honors.scss';
function Honors() {
    let { id, cn, eng, image } = honors_part;
    let { bot, trophy, block, list } = image;
    let [show, set_show] = useState<boolean>(false);
    return (
        <Fullpage id={id}>
            <InView className={id} set={set_show}>
                <Title name={cn} name_eng={eng}></Title>
                <div className="bot">
                    <img src={bot} alt="" />
                </div>
                <div className="block">
                    <img src={block} alt="" />
                    <div className="trophy">
                        <img src={trophy} alt="" />
                    </div>
                </div>
                <div className="content">
                    {list && list.map((el: any, i: number) => {
                        return <CardBox3D key={i} multiple={12}>
                            <img src={el} style={{ transition: show ? `all .3s ease ${0.5 + i * 0.2}s` : `none` }}></img>
                        </CardBox3D>
                    })}
                </div>
            </InView>
        </Fullpage>
    )
}

export default Honors;