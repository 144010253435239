import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { view } from '../../utils/router';
import HeaderNav from '../nav/header_nav';
import RightNav from '../nav/right_nav';
import LeftNav from '../nav/left_nav';
import './index_model.scss';
import BottomNav from '../nav/bottom_nav';
import { useNavigate } from 'react-router-dom';
const ModelContext = createContext<any>(undefined);
export const useModel = (() => {
    return useContext(ModelContext)
})
function prevent_default(e: any) {
    e.preventDefault();
}
function ModelIndex(props: view) {
    const navigate = useNavigate();
    // 当前页面url
    let [nowpage, setnowpage] = useState<string>('');
    // 当前距离顶部位置，视图向上缩进值
    let [top, set_top] = useState<number>(0);
    // 是否满屏滚动
    let [fullwheel, set_fullwheel] = useState<boolean>(false);
    // 当页的锚点列表，
    let [anchor, set_anchor] = useState<Array<string>>([]);
    // 跳转锚点
    let [jumpanchor, set_jumpanchor] = useState<string>();
    // 滑动动画持续时间，单位s
    let [dur, set_dur] = useState<number>(0.1);
    // 是否允许触发鼠标混动事件
    let [wheelable, set_wheelable] = useState<boolean>(true);
    // 顶部导航处于fixed状态的url集合
    const fixed_page = ['/'];
    // 可视高度，精确为整数，px
    const [vh, set_vh] = useState<number>(0);
    let wheelref = useRef<any>();
    useEffect(() => {
        // 设置可视高度
        set_vh(window.innerHeight);
    }, [])
    useEffect(() => {/* 初始化 */
        if (fullwheel) {
            // 取消鼠标滚动的默认事件
            wheelref.current.addEventListener('wheel', prevent_default, { passive: false })
            document.body.onkeydown = (e: any) => {
                if (e.code == 'Space') e.preventDefault();
            }
            window.onscroll = null;
        } else {
            wheelref.current.removeEventListener('wheel', prevent_default, { passive: false });
            document.body.onkeydown = null;
            window.onscroll = () => {
                set_top(document.documentElement.scrollTop);
            }
        }
    }, [fullwheel])
    useEffect(() => {/* 页面变动时动态寄存url */
        /* 获取页面内容节点，并通过获取到对应的子元素id来组成锚点列表
            检查子元素是否存在fullpage-wheel的class来决定是否满屏滚动 */
        setnowpage(props.url);
        let tar = document.getElementById('page')!.children[0];
        set_fullwheel(tar.className.indexOf('fullpage-wheel') !== -1);
        if (tar) {
            set_anchor(Array.from(tar.children).map(el => el.id));
        }
    }, [props])
    useEffect(() => {
        /* 
            锚点列表变动时，检查是否有跳转锚点id被寄存
            如果存在则调用选择锚点来滚动至对应位置，并清除寄存锚点
            如果不存在那么一般页面跳转到顶部位置
        */
        if (jumpanchor) {
            choice_anchor(jumpanchor);
            set_jumpanchor('');
        } else {
            set_top(0);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }, [anchor])
    function wheel(e: any) {
        if (fullwheel) {
            if (!wheelable) return;
            // 顶部导航高度
            let head_h = document.getElementById('header_nav')!.clientHeight;
            // 底部导航高度
            let bot_h = document.getElementById('bottom_nav')!.clientHeight;
            let mtop: number;/* 滚动至页面高度 */
            let dur: number = 0;/* 滚动动画时间 */
            set_wheelable(false);
            if (e.deltaY > 0) {
                /* 向下滑动 */
                if (!fixed_page.includes(nowpage) && top < head_h) {
                    /* 顶部距离小于顶部导航栏并且顶部导航栏不是浮动定位 */
                    dur = 0.3;
                    mtop = head_h;
                } else if (fixed_page.includes(nowpage) || !fixed_page.includes(nowpage) && top >= head_h) {
                    /* 不在顶部或者顶部导航栏为浮动定位 */
                    mtop = top + vh;
                    dur = 0.4;
                    /* 如果移动距离大于底部导航栏仅移动至底部导航高度 */
                    mtop = mtop > (document.body.scrollHeight - vh - bot_h) ? (document.body.scrollHeight - vh) : mtop;
                }
            } else if (e.deltaY < 0) {
                /* 向上滚动 */
                if (top - head_h <= 150) {
                    /* 小于等于顶部导航栏高度时，返回顶部 */
                    dur = 0.3;
                    mtop = 0;
                } else if (top > document.body.scrollHeight - vh - bot_h) {
                    /* 在底部时，移动一个底部导航栏距离 */
                    dur = 0.4;
                    mtop = document.body.scrollHeight - vh - bot_h;
                } else {
                    dur = 0.4;
                    mtop = top - vh;
                    mtop = mtop < 0 ? 0 : mtop;
                }
            }
            set_dur(dur);
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_top(mtop);
                timer = setTimeout(() => {
                    set_wheelable(true);
                }, (dur * 1000 - 50));
            }, 50);
        }
    }
    function choice_anchor(id: string, url?: string) {/* 选择锚点 */
        if (!wheelable) return;
        if (url) {
            /* 如果传入了页面，那么先寄存要跳转的锚点id再跳转 */
            set_jumpanchor(id);
            navigate(url);
        } else if (anchor && anchor.length !== 0 && anchor.indexOf(id) !== -1) {
            /* 先校验锚点是否在当前页面的可跳转锚点id列表中，存在则获取元素并滚动到对应位置 */
            set_dur(0.4)
            setTimeout(() => {
                let tar = document.getElementById(id);
                if (tar) {
                    if (fullwheel) set_top(tar.offsetTop);
                    else {
                        ani_scrollTo(tar.offsetTop);
                    }
                }
            }, 50);
        }
    }
    function ani_scrollTo(tpx: number, sp = 50) {/* 动画滚动 */
        set_wheelable(false);
        tpx = tpx > document.body.scrollHeight - vh ? document.body.scrollHeight - vh : tpx < 0 ? 0 : tpx;
        let timer = setInterval(() => {
            if (document.documentElement.scrollTop > tpx && document.documentElement.scrollTop - tpx <= sp || document.documentElement.scrollTop < tpx && tpx - document.documentElement.scrollTop <= sp || document.documentElement.scrollTop === tpx) {
                document.documentElement.scrollTop = tpx;
                document.body.scrollTop = tpx;
                set_wheelable(true);
                clearInterval(timer);
                return;
            }
            if (document.documentElement.scrollTop > tpx) {
                document.documentElement.scrollTop -= sp;
                document.body.scrollTop -= sp;
            } else if (document.documentElement.scrollTop < tpx) {
                document.documentElement.scrollTop += sp;
                document.body.scrollTop += sp;
            }
        }, 10)
    }
    function retop() {/* 返回顶部 */
        if (fullwheel) {
            set_wheelable(false);
            set_dur(0.4);
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_top(0);
                timer = setTimeout(() => {
                    set_wheelable(true);
                }, 350);
            }, 50);
        } else ani_scrollTo(0)
    }
    function rebot() {/* 返回底部 */
        if (fullwheel) {
            set_wheelable(false);
            set_dur(0.4);
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_top(document.body.scrollHeight - vh);
                timer = setTimeout(() => {
                    set_wheelable(true);
                }, 350);
            }, 50);
        } else ani_scrollTo(document.body.scrollHeight - vh);
    }
    return (
        <ModelContext.Provider value={{ page: nowpage, vh, top, dur, setanchor: choice_anchor, retop, rebot }}>
            <div className='model_index'>
                <div className="model_index_wheel_part" onWheel={wheel} ref={wheelref}>
                    <div style={{ transform: `translateY(-${fullwheel ? top : 0}px)`, transitionDuration: `${dur}s` }}>
                        <HeaderNav fixed={fixed_page.includes(nowpage)}></HeaderNav>
                        <div id='page'>{React.createElement(props.element.default, props.query)}</div>
                        <BottomNav></BottomNav>
                    </div>
                    <RightNav></RightNav>
                </div>
            </div>
        </ModelContext.Provider>
    )
}

export default ModelIndex;