import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import { culture_part } from "../../../../utils/registry";
import './culture.scss';

function Culture() {
    let { id, cn, eng, bg, text } = culture_part;
    return (
        <Fullpage id={id} style={{ backgroundImage: `url(${bg})` }}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                <InView className="culture_main">
                    {
                        text && text.map((el: any, i: number) => {
                            return <div className="txt_item" key={i}>
                                <div className="txt_item_title">
                                    {el.title.split('').map((txt: string, ii: number) => {
                                        return <div key={ii}>{txt}</div>
                                    })}
                                </div>
                                <div className={['txt_item_content', el.content.split('，').length == 3 ? 'three' : 'two'].join(' ')}>
                                    {
                                        el.content.split('，').map((ee: string, ei: number) => {
                                            return <div className="txt_item_content_item" key={ei}>
                                                <div>
                                                    {
                                                        ee.split('').map((txt: string, ii: number) => {
                                                            return <div key={ii}>{txt}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </InView>
            </div>
        </Fullpage>
    )
}

export default Culture;