
import { useModel } from '../../../../components/model/index_model';
import Title from '../../../../components/title/title';
import { jobitem, recruit_part, workrule } from '../../../../utils/registry';
import './recruit.scss';
function Recruit() {
    let { cn, eng, id, job } = recruit_part;
    let { vh } = useModel();
    return (
        <div id={id} style={{ minHeight: `${vh}px` }}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                {
                    job.length !== 0 ? <div className='job_list'>
                        {
                            job.map((el: jobitem, i: number) => {
                                return <div className='job_item' key={i}>
                                    <div className='job_title'>{el.name}</div>
                                    <div className='job_img'>
                                        <img src={el.img} alt="" />
                                    </div>
                                    <div className='rules'>
                                        {
                                            el.list.map((ee: workrule, ii: number) => {
                                                return <div className='rules_item' key={ii}>
                                                    <div className='rules_title'>{ee.title}</div>
                                                    <div className='details'>
                                                        {
                                                            ee.item.map((ed: string, ei: number) => {
                                                                return <div className='details_item' key={ei}>
                                                                    <span>{ei + 1}、</span><span>{ed}</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                        <div className='clear'></div>
                    </div> :
                        <div className='nodata'>暂无招聘</div>
                }
            </div>
        </div>
    )
}

export default Recruit;