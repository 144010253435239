import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import { introduce_part } from "../../../../utils/registry";
import './introduce.scss';
function Introduce() {
    let { id, cn, eng, img, text } = introduce_part;
    return (
        <Fullpage id={id} style={{ backgroundImage: `url(${img})` }}>
            <div className="glass">
                <Title name={cn} name_eng={eng}></Title>
                <div className={id}>
                    <InView className="content">
                        <div className="right_img">
                            <img src={img}></img>
                        </div>
                        <div className="txt_list">
                            {
                                text && text.split('\n').map((el: string, i: number) => {
                                    return <div className="txt" key={i}>{el}</div>
                                })
                            }
                        </div>
                    </InView>
                    <div className="clear"></div>
                </div>
            </div>
        </Fullpage>
    )
}

export default Introduce;