
import { useEffect, useRef, useState } from 'react';
import { filter, obj } from '../../utils/registry';
import './shoppingg.scss';
import FollowBall from '../followball/followball';
interface props {
    filter: Array<filter>,
    list: Array<obj>
}
function Shopping(props: props) {
    let { filter, list } = props;
    let [fshow, set_fshow] = useState<boolean>(true);
    let [typelist, set_typelist] = useState<obj>({});
    let [page, set_page] = useState<number>(1);
    let pagesize = useRef<number>(12);
    let [total, set_total] = useState<number>(0);
    let [pagelist, set_pagelist] = useState<Array<JSX.Element>>();
    let [showlist, set_showlist] = useState<Array<obj>>([]);
    let [filter_h, set_filter_h] = useState<number>();
    let filterin = useRef<any>();
    useEffect(() => {
        set_filter_h(filterin.current.offsetHeight);
    }, [])
    useEffect(() => {
        set_page(1);
        make_list(typelist, 1);
    }, [typelist])
    useEffect(() => {
        make_list(typelist, page);
    }, [page])
    function make_list(type: obj, page: number) {
        let _list:Array<obj> = [];
        try {
            if (JSON.stringify(type) == '{}') {
                _list = list;
            } else {
                _list = list.filter((el: obj, i: number) => {
                    for (let key in type) {
                        if (!type[key] || el[key] && el[key] == type[key]) continue;
                        else {
                            return false
                        }
                    }
                    return true;
                })
            }
        } catch {
            _list = list;
        }
        let _total = Math.ceil(_list.length / pagesize.current)
        set_total(_total);
        let _pages = [];
        let _index = [];
        for (let i = 1; i <= _total; i++) {
            if (i <= 3 || i > _total - 3 || i >= page - 1 && i <= page + 1) {
                _pages.push(<div className={['pitem', page === i ? 'active' : ''].join(' ')} onClick={() => set_page(i)} key={i}>{i}</div>);
                _index.push(i);
            }
        }
        if (_total <= 6) {
            // 结束
        } else if (page <= 2 || page > _total - 2) {
            _pages.splice(3, 0, <div key='three_end'>···</div>)
        } else {
            if (page > 5) {
                _pages.splice(_index.indexOf(page) - 1, 0, <div key='page_front'>···</div>)
            }
            if (page < total - 4) {
                _pages.splice(_index.indexOf(page) + 3, 0, <div key='page_end'>···</div>)
            }
        }
        set_pagelist(_pages);
        _list = _list.filter((el: obj, i: number) => {
            return i >= (page - 1) * pagesize.current && i < page * pagesize.current;
        })
        set_showlist(_list);
    }
    function change_fshow() {
        set_fshow(!fshow);
    }
    function change_type(type: string, value: string) {
        set_typelist({ ...typelist, [type]: value });
    }
    function clear_type() {
        set_typelist({});
    }
    function prev_page() {
        if (page === 1) return;
        set_page(--page);
    }
    function last_page() {
        if (page == showlist.length) return;
        set_page(++page)
    }
    return (
        <div className="shopping">
            <div className='filter_part'>
                <div className='filter_part_head'>
                    <div className='head_title'>筛选</div>
                    <div className='options'>
                        <div onClick={clear_type}>清空筛选</div>
                        <div onClick={change_fshow}>{fshow ? '隐藏' : '展开'}</div>
                    </div>
                </div>
                <div className={['filter_content', fshow ? 'show' : ''].join(' ')} style={{ height: `${fshow ? filter_h : 0}px` }}>
                    <div ref={filterin}>
                        {filter.map((el: filter, i: number) => {
                            return <div className='filter_content_item' key={i}>
                                <div className='title'>{el.title}</div>
                                <div className='filter_list'>
                                    <div className={!typelist[el.prop] ? 'active' : ''} onClick={() => change_type(el.prop, '')}>默认</div>
                                    {el.list.map((ee: any, ii: number) => {
                                        return <div key={ii} className={typelist[el.prop] == ee.prop ? 'active' : ''} onClick={() => change_type(el.prop, ee.prop)}>{ee.title}</div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className='good_part'>
                {
                    showlist.length !== 0 ? <div className='good_list'>
                        {
                            showlist.map((el: any, i: number) => {
                                return <div key={i}>
                                    <FollowBall className='good_item' bgcolor='#fff' auto={true}>
                                        <div className='imgbox'>
                                            <img src={el.image[0]}></img>
                                        </div>
                                        <div className='content'>
                                            <div className='title'>{el.title}</div>
                                            <div className='price_part'>
                                                {el.price && <span className='yuan'>¥</span>}
                                                <span className='num'>{el.price || '待定'}</span>
                                            </div>
                                            <div className='last'>
                                                <div className='provider'>{el.provider}</div>
                                                <div className='address'>{el.location}</div>
                                            </div>
                                        </div>
                                    </FollowBall>
                                </div>
                            })
                        }
                    </div>
                        : <div className='nodata'>暂无内容</div>
                }
                <div className='paging'>
                    <div className='chioce_part'>
                        <div className={['prev', 'svgbox', page == 1 ? 'disabled' : ''].join(' ')} onClick={prev_page}>
                            <svg viewBox='0 0 16 16'>
                                <path d='M10 3 l-6 5 l6 5'></path>
                            </svg>
                        </div>
                        <div className='pagenum'>
                            {pagelist}
                        </div>
                        <div className={['last', 'svgbox', page == total ? 'disabled' : ''].join(' ')} onClick={last_page}>
                            <svg viewBox='0 0 16 16'>
                                <path d='M6 3 l6 5 l-6 5'></path>
                            </svg>
                        </div>
                    </div>
                    <div className='total'>共 {total} 页</div>
                </div>
            </div>
        </div>
    )
}

export default Shopping;