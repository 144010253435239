import { useEffect, useState } from "react";
import Fullpage from "../../../../components/fullpage/fullpage";
import InView from "../../../../components/inview/inview";
import Title from "../../../../components/title/title";
import { idea_part } from "../../../../utils/registry";
import './idea.scss';

function Idea() {
    let { id, cn, eng, bg, text } = idea_part;
    let [show, set_show] = useState<boolean>(false);
    let [showtxt, set_showtxt] = useState<string>('');
    let [timer, set_timer] = useState<any>();
    useEffect(() => {
        if (show) {
            set_showtxt(text.cn.slice(0, 1));
        } else {
            set_showtxt('');
        }
    }, [show])
    useEffect(() => {
        if (showtxt) {
            if (showtxt.length == text.cn.length) return;
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_showtxt(text.cn.slice(0, showtxt.length + 1))
            }, Math.random() * 30);
            set_timer(timer);
        } else {
            clearTimeout(timer);
            set_timer(null);
        }
    }, [showtxt])
    return (
        <Fullpage id={id} style={{ backgroundImage: `url(${bg})` }}>
            <div>
                <Title name={cn} name_eng={eng}></Title>
                <InView className={id} set={set_show}>
                    <div className="content"><span>{showtxt}</span>{showtxt.length !== text.cn.length && <span className='_end'>_</span>}</div>
                </InView>
            </div>
        </Fullpage>
    )
}

export default Idea;