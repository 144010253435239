
import { MouseEventHandler, useEffect, useState } from 'react';
import { useModel } from '../../../../components/model/index_model';
import Title from '../../../../components/title/title';
import { cases_item, more_cases_part } from '../../../../utils/registry';
import './cases.scss';
function MoreCases() {
    let { cn, eng, id, items, filter } = more_cases_part;
    let [active, set_active] = useState<number | undefined>();
    let [showlist, set_showlist] = useState<Array<cases_item>>([]);
    let { vh } = useModel();
    useEffect(() => {
        let list: Array<cases_item> = items.filter((el: any) => {
            return active === undefined || el.type === active
        })
        set_showlist(list);
    }, [active])
    function clear_active() {
        set_active(undefined);
    }
    function read_details() {
        
    }
    return (
        <div id={id} style={{ minHeight: `${vh}px` }}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                <div className='filter_part'>
                    <FilterItem className={['all', active === undefined ? 'active' : '']} onclick={clear_active}>全部</FilterItem>
                    {
                        filter.map((el: any, i: number) => {
                            return <FilterItem key={i} className={active === i ? 'active' : ''} onclick={() => set_active(i)}>{el.cn}</FilterItem>
                        })
                    }
                </div>
                <div className='content_part'>
                    {
                        showlist.length !== 0 ? <div className='cases_list'>
                            {
                                showlist.map((el: any, i: number) => {
                                    return (
                                        <div className='cases_item' key={i}>
                                            <div className='cases_img'>
                                                <img src={el.img} alt="" />
                                            </div>
                                            <div className='cases_content'>
                                                <div className='cases_title'>
                                                    {el.cn}案例
                                                </div>
                                                <div className='cases_txt'>
                                                    {el.txt}
                                                </div>
                                                <div className='details' onClick={read_details}>
                                                    查看详情
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> : <div className='nodata'>暂无案例</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MoreCases;

interface item {
    children: string,
    onclick?: MouseEventHandler<HTMLDivElement>,
    className?: string | Array<string>,
}

function FilterItem(props: item) {
    let { children, onclick, className } = props;
    return (
        <div className={['filter_item'].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')} onClick={onclick}>
            <div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}