import Fullpage from "../../../../components/fullpage/fullpage";
import Title from "../../../../components/title/title";
import './business.scss';
import { business_part } from "../../../../utils/registry";
import CardBox3D from "../../../../components/cardbox3d/cardbox3d";
import InView from "../../../../components/inview/inview";
import { useEffect, useState } from "react";
function Bussiness() {
    const { id, cn, eng, items } = business_part;
    let [show, set_show] = useState<boolean>(false);
    let [timer, set_timer] = useState<any>();
    useEffect(() => {
        if (show) {
            let timer = setInterval(() => {
                make_meteor();
            }, 300)
            set_timer(timer);
        } else {
            clearInterval(timer);
            set_timer(null);
        }
    }, [show])
    function make_meteor() {
        let e = document.createElement('div');
        let classlist = ['toleft', 'toright', 'totop', 'tobot'];
        let className = classlist[Math.floor(Math.random() * classlist.length)];
        let father = document.getElementById(id)!;
        let width = Math.floor(Math.random() * 16 + 5)
        e.style.width = `${width}px`;
        e.style.boxShadow = `0 0 ${width}px #0f0, 0 0 ${width*2}px #0f0, 0 0 ${width * 3}px #0f0, 0 0 ${width * 4}px #0f0`;
        let dur = Math.floor(Math.random() * 30 + 10) / 10;
        e.style.animationDuration = `${dur}s`;
        if (className == 'toleft' || className == 'toright') {
            e.style.top = `${Math.floor(Math.random() * (father.offsetHeight + 1))}px`;
        } else {
            e.style.left = `${Math.floor(Math.random() * (father.offsetWidth + 1))}px`;
        }
        e.setAttribute('class', `meteor ${className}`);
        father.appendChild(e);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            father.removeChild(e);
        }, dur * 1000);
    }
    return (
        <Fullpage id={id}>
            <Title name={cn} name_eng={eng}></Title>
            <InView set={set_show} className={'bin'}>
                <div className={id} style={{ gridTemplateColumns: `repeat(${items.length},1fr)` }}>
                    {items.map((el: any, i: number) => {
                        return <CardBox3D key={i} className="box3d" sonclassName="business_item">
                            <div className="business_item_icon">
                                {el.img && [
                                    <img className="default_img" src={el.img} alt="" key="default"></img>,
                                    <img className="active_img" src={el.img_active} alt="" key="active" />
                                ]}
                                {el.svg && el.svg}
                            </div>
                            <div className="business_item_title">{el.cn}</div>
                            <div className="business_item_text">{el.text.cn}</div>
                        </CardBox3D>
                    })}
                </div>
            </InView>
        </Fullpage>
    )
}

export default Bussiness;