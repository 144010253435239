
import { useEffect, useRef } from 'react';
import './followball.scss';
interface props {
    children: JSX.Element | Array<JSX.Element>,
    className?: string | Array<string>,
    bgcolor: string,
    auto?: boolean
}
function FollowBall(props: props) {
    let { children, className, bgcolor, auto } = props;
    let self = useRef<any>();
    useEffect(() => {
        self.current.style.setProperty('--bg', bgcolor)
    }, [bgcolor])
    function mouse_move(e: any) {
        if (auto || !self.current) return;
        self.current.style.setProperty('--x', `${e.clientX - self.current.offsetLeft}px`);
        self.current.style.setProperty('--y', `${e.clientY - self.current.getBoundingClientRect().top}px`);
    }
    return (
        <div className={['follow_ball', auto ? 'auto' : ''].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')} ref={self} onMouseMove={mouse_move}>
            {children}
        </div>
    )
}

export default FollowBall;