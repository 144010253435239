export interface _element {
    default: string
}

export interface view {
    url: string,/* 网页路劲 */
    name: string,/* 名称 */
    name_eng?: string,/* 名称英文 */
    element: _element,/* 组件路径 */
    model?: _element,/* 模版路径 */
    query?: { [key: string]: any },/* 请求 */
    roles?: Array<string>,/* 角色权限 */
    permissions?: Array<string>,/* 权限 */
    children?: Array<view>,/* 子页面 */
    id?: string | number,/* 唯一字符 */
}
/* 展示优先级，子页面模版、父页面模版、子页面、父页面 */
let model = require('../components/model/index_model');
export const routes: Array<view> = [
    {
        url: '/',
        name: '主页',
        element: require('../views/index/index'),
        model,
        children: [
            {
                url: '/business_case',
                name: '更多案例',
                element: require('../views/index/pages/cases/more_cases')
            }
        ]
    },
    {
        url: '/about_us',
        name: '关于我们',
        element: require('../views/about_us/index'),
        model,
        children: [
            {
                url: '/recruit',
                name: '人才招募',
                element: require('../views/about_us/pages/recruit/recruit')
            }
        ]
    },
    {
        url: '/equipment',
        name: '设备',
        element: require('../views/shopc/index'),
        model,
        query: { type: 'equipment' },
    },
    {
        url: '/material',
        name: '材料',
        element: require('../views/shopc/index'),
        model,
        query: { type: 'material' },
    },
    {
        url: '/warehouse',
        name: '仓库',
        element: require('../views/warehouse/index'),
        model,
    },
    {
        url: '/company',
        name: '企业',
        element: require('../views/company/index'),
        model,
    },
    {
        url: '/login',
        name: '登录',
        element: require('../views/login/login'),
        model,
    }
]