import { useEffect, useState } from 'react';
import Fullpage from '../../../../components/fullpage/fullpage';
import Title from '../../../../components/title/title';
import { coordinate, development_part } from '../../../../utils/registry';
import './development.scss';
import InView from '../../../../components/inview/inview';
function Development() {
    let { id, cn, eng, text, map, coordinate } = development_part;
    let [pathlist, set_pathlist] = useState<Array<coordinate>>();
    let [_coo, set_coo] = useState<coordinate>();
    let [show, set_show] = useState<boolean>(false);
    let [showtxt, set_showtxt] = useState<string>('');
    let [timer, set_timer] = useState<any>();
    useEffect(() => {
        let _coordinate = [...coordinate];
        let _coo = _coordinate.shift();
        set_coo(_coo)
        set_pathlist(_coordinate);
    }, [coordinate])
    useEffect(() => {
        if (show) {
            set_showtxt(text.cn.slice(0, 1));
        } else {
            set_showtxt('');
        }
    }, [show])
    useEffect(() => {
        if (showtxt) {
            if (showtxt.length == text.cn.length) return;
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_showtxt(text.cn.slice(0, showtxt.length + 1))
            }, Math.random() * 50);
            set_timer(timer);
        } else {
            clearTimeout(timer);
            set_timer(null);
        }
    }, [showtxt])
    return (
        <Fullpage id={id}>
            <InView className={id} set={set_show}>
                <div className='left_part'>
                    <div>
                        <Title name={cn} name_eng={eng}></Title>
                        <div className='content'>{showtxt}{showtxt.length !== text.cn.length && <span className='content_end'>_</span>}</div>
                    </div>
                </div>
                <div className='right_part'>
                    <div className='map'>
                        <img src={map} alt="" className='map' />
                        <svg className='draw' viewBox='0 0 1954 1612'>
                            <defs>
                                <radialGradient id='show_pro_cap'>
                                    <stop offset="30%" stopColor='transparent'></stop>
                                    <stop offset="100%" stopColor='rgb(236,28,37)'></stop>
                                </radialGradient>
                            </defs>
                            {_coo && [
                                <circle cx={_coo.x} cy={_coo.y} r="6" className='pro_cap' key={`circle_o`}></circle>,
                                <circle cx={_coo.x} cy={_coo.y} r="24" key={`circle_out_o`} fill="url(#show_pro_cap)" className='pro_cap_out' style={{ transformOrigin: `${_coo.x}px ${_coo.y}px` }}></circle>
                            ]}
                            {pathlist && pathlist.map((el: any, i: number) => {
                                return [
                                    <circle cx={el.x} cy={el.y} r="6" key={`circle_${i}`} className='pro_cap'></circle>,
                                    <circle cx={el.x} cy={el.y} r="24" key={`circle_out_${i}`} fill="url(#show_pro_cap)" className='pro_cap_out' style={{ transformOrigin: `${el.x}px ${el.y}px` }}></circle>,
                                    _coo && <path d={`M${_coo.x} ${_coo.y} ${el.q ? `Q${el.q.x} ${el.q.y} ${el.x} ${el.y}` : `L${el.x} ${el.y}`}`} stroke='rgb(104,162,253)' strokeWidth="5" key={`path${i}`} fill='none' className='path' style={{
                                        strokeDasharray: el.q ? el.q.l : Math.sqrt(((el.x - _coo.x) ** 2 + (el.y - _coo.y) ** 2)),
                                        strokeDashoffset: show ? 0 : el.q ? el.q.l : Math.sqrt(((el.x - _coo.x) ** 2 + (el.y - _coo.y) ** 2)),
                                        transition: `all ${show ? 0.6 : 0.1}s ease-out ${show ? 0.3 : 0}s`
                                    }}></path>
                                ]
                            })}
                        </svg>
                    </div>
                </div>
            </InView>
        </Fullpage>
    )
}

export default Development;