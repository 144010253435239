import BaiduMap from "../../../../components/baidumap/baidumap";
import Fullpage from "../../../../components/fullpage/fullpage";
import Title from "../../../../components/title/title";
import { contactus_part } from "../../../../utils/registry";

import './contact_us.scss';
function ContactUs() {
    let { id, cn, eng, name, address, phone, email, keys, center } = contactus_part;
    return (
        <Fullpage id={id}>
            <Title name={cn} name_eng={eng}></Title>
            <div className={id}>
                <div className="box">
                    <div className="company_info">
                        <div className="title">{name}</div>
                        <div className="content">
                            <div>地址：{address}</div>
                            <div>手机：{phone}</div>
                            <div>邮箱：{email}</div>
                        </div>
                    </div>
                    <div className="map">
                        <BaiduMap keys={keys} zoom={19} center={center}></BaiduMap>
                    </div>
                </div>
            </div>
        </Fullpage>
    )
}

export default ContactUs;